* {
	font-family: 'Roboto', sans-serif;
	padding: 0;
	margin: 0;
}

body {
	background-color: rgb(165, 152, 152);
}

h2 {
	color: #fff;
}

.App {
	background-color: rgb(165, 152, 152);
}

.icon-basket {
	padding-left: 165px;
	padding-bottom: 70px;
	font-size: 31px;
	color: white;
}

.fas {
	padding-right: 150px;
}

.item-card {
	border: 2px solid wheat;
}

.all-products {
	padding-left: 40px;
}

.padding-text {
	padding-left: 65px;
}

.ui.cards > .card > .image {
	height: 300px;
	padding: 20px;
	background: #fff;
	margin: auto;
}

.ui.cards > .card > .image > img {
	height: 100%;
	max-width: 100%;
	width: auto;
}

.ui.cards > .card > .content > .header {
	height: 48px;
	overflow: hidden;
	margin-bottom: 5px;
}

.ui.cards > .card > .content > .description {
	height: 36px;
	margin-bottom: 0px;
	overflow: hidden;
}

.ui.cards > .card .meta.price {
	margin-bottom: 5px;
	font-size: 18px;
	color: #333;
	font-weight: 600;
}

.ui.cards > .card .meta.price > a {
	font-size: 1.3rem;
	color: #222;
}

.ui.menu.fixed {
	height: 60px;
	padding-top: 15px;
	background-color: rgb(174, 135, 135);
	position: relative;
	padding-left: 65px;
}

.ui.grid.container {
	margin-top: 10px;
}

.ui.grid > .row {
	background: #fff;
}

.ui.grid > .row > .column.lp {
	padding: 20px 40px 20px 20px;
	align-self: flex-start !important;
}

.ui.grid > .row > .column.rp {
	padding: 20px 20px 20px 40px;
	text-align: left;
	align-self: flex-start !important;
}

.ui.grid > .row > .column > img,
.ui.grid > .row > img {
	height: 100%;
}

.ui.placeholder .header:not(:first-child):before,
.ui.placeholder .image:not(:first-child):before,
.ui.placeholder .paragraph:not(:first-child):before {
	display: none;
}

.ui.label,
.ui.labels .label {
	font-size: 21px;
}

.column.rp h1 {
	color: black;
}

.column.rp p {
	font-size: 18px;
	color: rgb(41, 39, 39);
}

.ui.placeholder.segment .column .button,
.ui.placeholder.segment .column .field,
.ui.placeholder.segment .column textarea,
.ui.placeholder.segment .column > .ui.input {
	background-color: #ff3e6c;
	border: 1px solid #ff3e6c;
	color: #fff;
	font-size: 18px;
	margin-left: 0;
}

.count {
	font-size: 25px;
	color: #fc5a5a;
	padding-bottom: 15px;
}

.container {
	display: flex;
}

.container-product {
	padding-top: 30px;
	margin: 0 auto;
}

.product-item {
	width: 70%;
	text-align: center;
	margin: 0 auto;
	background: white;
	font-family: 'Open Sans';
	transition: 0.3s ease-in;
}

.product-item:hover {
	border-bottom: 2px solid #fc5a5a;
}

.product-item img {
	display: block;
	width: 100%;
}

.product-list {
	background: #fafafa;
	padding: 15px 0;
}

.product-list h3 {
	font-size: 27px;
	font-weight: 400;
	color: #444444;
	margin: 0 0 10px 0;
}

.price {
	font-size: 20px;
	color: #fc5a5a;
	display: block;
	margin-bottom: 12px;
}

.category {
	font-size: 16px;
}

.fa-shopping-basket:before {
	color: white;
}

.gradient-button {
	text-decoration: none;
	display: inline-block;
	color: white;
	padding: 9px 18px;
	margin: 10px 20px;
	border-radius: 7px;
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
	letter-spacing: 2px;
	background-image: linear-gradient(
		to right,
		rgb(165, 152, 152) 0%,
		#fc5a5a 51%,
		#fc5a5a 100%
	);
	background-size: 200% auto;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	transition: 0.5s;
}

.gradient-button:hover {
	background-position: right center;
}

.button-container {
	display: flex;
	justify-content: center;
	padding-top: 20px;
}

.button-order {
	font-weight: 700;
	color: white;
	text-decoration: none;
	padding: 0.8em 1em calc(0.8em + 3px);
	border-radius: 3px;
	background: #fc5a5a;
	box-shadow: 0 -3px #fc5a5a inset;
	transition: 0.2s;
}

.button-order:hover {
	background: #fc5a5a;
}
.button-order:active {
	background: #fc5a5a;
	box-shadow: 0 3px #fc5a5a inset;
}
